const personalInfo = {
    // Corporate
    corporateInformation: "公司信息",
    corporationLegalName: "公司法定名称",
    businessIDOrTaxIDOrEquivalent: "商业识别号、税号或同等编号",
    incorporationNumber: "公司注册号",
    websiteURLOptional: "网站网址（可选）",
    country: "国家",
    contactInformation: "联系信息",
    phoneNumber: "电话号码",
    email: "电子邮件",
    bankPostalCode: "银行邮政编码",
    signatureRequirement: "需要多少个签名才能在账户上进行交易？",
    buildingNumber: "建筑编号",
    streetAddress: "街道地址",
    aptSuiteOrUnitOptional: "公寓、套房或单元号（可选）",
    city: "城市",
    stateProvince: "州/省",
    postalCodeZIPCode: "邮政编码/邮政编码",
    businessActivities: "业务活动",
    primary: "主要的",
    secondaryOptional: "次要的（可选）",
    tertiaryOptional: "第三的（可选）",
    businessRegistrations: "商业注册",
    companyRegulatedQuestion: "您的公司是否受到任何联邦、州和/或省级监管机构的监管或注册？",
    sourceOfFund: "资金来源",
    purposeOfAccount: "账户目的",
    // personal - the basic info can be found in account
    residenceMailingAddress: "住宅和邮寄地址",
    buildingNumber: "大楼号码",
    streetAddress: "街道地址",
    aptSuiteOrUnitOptional: "公寓，套间或单元号（可选）",
    gifterFirstName: "赠送者名字",
    gifterLastName: "赠送者姓氏",
    gifterDateOfBirth: "赠送者出生日期",
    gifterDetailedAddress: "赠送者详细地址",
    relationship: "关系",
    gifterJobTitle: "赠送者职位",
    gifterEmployer: "赠送者雇主",
    gifterIncome: "赠送者收入",
    gifterJobFunction: "赠送者职位功能",
    gifterSourceOfFunds: "赠送者资金来源",
    gifterValue: "赠送者金额",
    gifterPurpose: "赠送者目的",
    pastInvestment: "过去的投资",
    investmentType: "投资类型",
    investmentDate: "投资日期",
    investmentAmount: "投资金额",
    investmentProfit: "投资收益",
    sourceOfFund: "资金来源",
    purposeOfAccount: "账户用途",
    employmentInformation: "就业信息",
    currentEmploymentStatus: "当前就业状态",
    yearStart: "开始年份",
    yearEnd: "结束年份",
    industry: "行业",
    nameOfEmployer: "雇主名称",
    countryOfEmployment: "工作国家",
    titleRole: "头衔/角色",
    department: "部门",
    employerWebsiteOptional: "雇主网站（可选）",
    salaryRange: "薪资范围",
    attestation: "认证",
    sanctionQuestion: "您是否曾被联合国、任何自律组织、任何国家主管部门或其他金融监管机构制裁？",
    pepQuestionDetailed: "您是否被认为是政治人物 (PEP)、外国政治人物 (PEFP)、国内政治人物 (PEDP)、国际组织负责人 (HIO) 或此类人的家庭成员或密切联系人？",
    customerId: "客户ID",
    walletId: "钱包ID"
};

export default personalInfo;
