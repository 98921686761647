const personalInfo = {
    // Corporate
    corporateInformation: "Corporate Information",
    corporationLegalName: "Corporation Legal name",
    businessIDOrTaxIDOrEquivalent: "Business Identification Number, Tax Identification Number, or Equivalent",
    incorporationNumber: "Incorporation Number",
    websiteURLOptional: "Website URL (optional)",
    country: "Country",
    contactInformation: "Contact Information",
    phoneNumber: "Phone Number",
    email: "Email",
    bankPostalCode: "Bank Postal Code",
    signatureRequirement: "How many signatures are required to transact on the account?",
    buildingNumber: "Building Number",
    streetAddress: "Street Address",
    aptSuiteOrUnitOptional: "Apt, Suite or Unit (optional)",
    city: "City",
    stateProvince: "State / Province",
    postalCodeZIPCode: "Postal Code / ZIP Code",
    businessActivities: "Business Activities",
    primary: "Primary",
    secondaryOptional: "Secondary (optional)",
    tertiaryOptional: "Tertiary (optional)",
    businessRegistrations: "Business Registrations",
    companyRegulatedQuestion: "Is your company regulated or registered with any Federal, State, and/or Provincial regulator?",
    sourceOfFund: "Source of Fund",
    purposeOfAccount: "Purpose of Account",
    // personal - the basic info can be found in account
    residenceMailingAddress: "Residence & Mailing Address",
    buildingNumber: "Building Number",
    streetAddress: "Street Address",
    aptSuiteOrUnitOptional: "Apt, Suite or Unit (optional)",
    gifterFirstName: "Gifter First Name",
    gifterLastName: "Gifter Last Name",
    gifterDateOfBirth: "Gifter Date of Birth",
    gifterDetailedAddress: "Gifter Detailed Address",
    relationship: "Relationship",
    gifterJobTitle: "Gifter Job Title",
    gifterEmployer: "Gifter Employer",
    gifterIncome: "Gifter Income",
    gifterJobFunction: "Gifter Job Function",
    gifterSourceOfFunds: "Gifter Source of Funds",
    gifterValue: "Gift Value",
    gifterPurpose: "Gift Purpose",
    pastInvestment: "Past Investment",
    investmentType: "Investment Type",
    investmentDate: "Investment Date",
    investmentAmount: "Investment Amount",
    investmentProfit: "Investment Profit",
    sourceOfFund: "Source of Fund",
    purposeOfAccount: "Purpose of Account",
    employmentInformation: "Employment Information",
    currentEmploymentStatus: "Current Employment Status",
    yearStart: "Year Start",
    yearEnd: "Year End",
    industry: "Industry",
    nameOfEmployer: "Name of employer",
    countryOfEmployment: "Country of employment",
    titleRole: "Title / Role",
    department: "Department",
    employerWebsiteOptional: "Employer website (optional)",
    salaryRange: "Salary Range",
    attestation: "Attestation",
    sanctionQuestion: "Have you ever been sanctioned by the United Nations, any Self-Regulatory Organization, any National Competent Authority, or other financial regulator?",
    pepQuestionDetailed:
        "Are you considered to be a Politically Exposed Person (PEP), a politically exposed foreign person (PEFP); a politically exposed domestic person (PEDP); a head of an international organization (HIO); or a Family Member or Close Associate of such person (collectively, PEPs)?",
    customerId: "Customer ID",
    walletId: "Wallet ID"
};
export default personalInfo;
