const layout = {
    general: "General",
    dashboard: "Dashboard",
    notification: "Notification",
    beneficiaries: "Beneficiaries",
    sendMoney: "Send Money",
    transaction: "Transaction",
    tutorial: "Tutorial",
    faq: "FAQ",
    account: "Account",
    signout: "Sign out",
    signoutDesc: "Are you sure you want to sign out?",
    confirm: "Confirm",
    lng:"English",
}

export default layout;