const layout = {
    general: "常规",
    dashboard: "数据面板",
    notification: "通知",
    beneficiaries: "收款人",
    sendMoney: "汇款",
    transaction: "交易",
    tutorial: "教程",
    faq: "常见问题",
    account: "账户",
    signout: "退出登录",
    signoutDesc: "您确定要退出登录吗？",
    confirm: "确认",
    lng:"简体中文",
};

export default layout;
