const transaction = {
    transactionHistory: "交易历史",
    transactionDetails: "交易详情",
    sentEFT: "发送的电子资金转账",
    receivedEFT: "接收的电子资金转账",
    sentWire: "发送的电汇",
    receivedWire: "接收的电汇",
    walletTransfer: "钱包转账",
    dateRange: "日期范围",
    type: "类型",
    status: "状态",
    clearFilter: "清除筛选器",
    downloadAll: "下载所有",
    clear: "清除",
    receiver: "接收者",
    dateAndTime: "日期和时间",
    sender: "发送者",
    number: "编号",
    direction: "方向",
    uniqueID: "唯一标识",
    amount: "金额",
    description: "描述",
    type: "类型",
    status: "状态",
    action: "操作",
    debit: "借记",
    pending: "待处理",
    credit: "贷记",
    settled: "已结算",
    returned: "已退回",
    perPage: "每页",
    totalSent: "总发送",
    fee: "费用",
    totalAmount: "总金额",
    // detail
    bankNumber: "银行编号",
    receiverTransitNumber: "接收者过境编号",
    receiverAccountNumber: "接收者账户编号",
    purposeOfPayment: "付款目的",
    description: "描述",
    sundryInformationOptional: "杂项信息（可选）",
    crossReferenceNumber: "交叉参考编号",
    returnCode: "退货代码",
    returnDate: "退货日期",
    returnReason: "退货原因",
    downloadSuccessful: "下载成功！",
    pleaseSelect: "请选择要下载的行",
    receiverWalletId: "接收者钱包ID",
    senderWalletId: "发送者钱包ID",
    back: "返回"
};

export default transaction;
