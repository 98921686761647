const dashboard = {
    hello: "欢迎",
    totalBalance: "总余额",
    asOn: "截至",
    availableBalance: "可用余额",
    liquidityReserve: "流动性储备",
    notification: "通知",
    pendingBalance: "待处理余额",
    createABeneficiary: "创建收款人",
    sendSingleEFT: "发送单笔EFT",
    sendWire: "发送电汇",
    sentEFT: "发送EFT",
    receivedEFT: "接收EFT",
    sentWire: "已发电汇",
    dateRange: "日期范围",
    type: "类型",
    status: "状态",
    clearFilter: "清除筛选",
    viewCompleteHistory: "查看完整历史记录",
    number: "编号",
    receiver: "收款人",
    dateAndTime: "日期和时间",
    amount: "金额",
    description: "描述",
    wireStatement: "汇款明细",
    viewAll: "查看全部",
};
export default dashboard;
