import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout as AntdLayout, Menu, Spin, message, Dropdown } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import cx from "classnames";
import {
    AccountIcon,
    BeneficiariesIcon,
    DashboardIcon,
    FaqIcon,
    NotificationIcon,
    SendMoneyIcon,
    SignoutIcon,
    SmallWealthLogo,
    TransactionIcon,
    TutorialIcon,
    WealthLogo,
} from "./Icons";
import Button from "./button";
import "./styles/layout.css";
import { Header } from "antd/es/layout/layout";
import { useTranslation } from "react-i18next";
import { logout } from "utils/common";
import { NotificationBadge } from "./NotificationBadge";
import AccountAPI from "fetch/account";
import { usePermissions } from "store/permission";
import NotificationAPI from "fetch/notification";
import { useNewNotifications } from "store/newNotification";
import GeneralModal from "./beneficiary/GeneralModal";
import languageIcon from "../static/imgs/common/Language.svg"
import arrowDownIcon from "../static/imgs/common/arrowDown.svg"


const { Sider, Content } = AntdLayout;

const Layout = ({
    children,
    contentClassnames,
    isLoading,
    headerPath,
    isHidden = false,
}) => {
    const { t, i18n } = useTranslation("layout");
    const { savePermissions } = usePermissions();
    const { newNotifications, saveNewNotifications } = useNewNotifications();
    const payAccountId =
        JSON.parse(localStorage.getItem("payAuth"))?.payAccountId ?? null;
    const payAccountUserId =
        JSON.parse(localStorage.getItem("payAuth"))?.userId ?? null;
    const [sidebarCollpased, setSidebarCollpased] = useState(false);
    const [headerCollapsed, setHeaderCollapsed] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const firstSegment = pathSegments[0] || "dashboard";
    const [notificationsNum, setNotificationsNum] = useState(null);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [tutorialDownloadUrl, setTutorialDownloadUrl] = useState(null);
    const url = "/" + pathSegments;

    // i18n select
    const langMenuList = [
        {
            lable: "English",
            value: "en",
        },
        {
            lable: "简体中文",
            value: "zh",
        },
    ]
    const handleLangLink = (e) => {
        i18n.changeLanguage(e.key)
        localStorage.setItem("language",e.key)
    }
    const langMenu = (
        <Menu selectable
            onClick={handleLangLink}
        >
            {
                langMenuList.map(item => {
                    return (
                        <Menu.Item key={item.value}>{t(item.lable)}</Menu.Item>
                    )
                })
            }
        </Menu>
    )

    const fetchPermissions = useCallback(async () => {
        try {
            const data = {
                payAccountUserId,
                payAccountId,
            };
            const [permissionRes, rolesRes] = await Promise.all([
                AccountAPI.userDetails(data),
                AccountAPI.getRoleList(),
            ]);
            const [permissionData, rolesData] = await Promise.all([
                permissionRes.data,
                rolesRes.data,
            ]);
            const roleInfo = rolesData.find(
                (r) => r.roleName === permissionData.permission
            );
            const permissions = {
                isApprove: roleInfo.isApprove,
                isIniate: roleInfo.isIniate,
                isRead: roleInfo.isRead,
                isUserManagement: roleInfo.isUserManagement,
                roleCode: roleInfo.roleCode,
            };
            savePermissions(permissions);
        } catch (e) {
            const errorMessage =
                e?.response?.data?.message ||
                e.message ||
                "An error occurred while fetching permissions.";
            message.error(errorMessage);
        }
    }, [payAccountId, payAccountUserId, savePermissions]);

    const fetchNotifications = useCallback(async () => {
        try {
            const res = await NotificationAPI.getNewNotificationsNumber();
            const data = await res.data.newNotificationNumber;
            const notificationCount =
                typeof data === "number" ? (data <= 99 ? data : "99+") : 0;
            setNotificationsNum(notificationCount);
            saveNewNotifications(notificationCount);
        } catch (e) {
            const errorMessage =
                e?.response?.data?.message ||
                e.message ||
                "An error occurred while fetching notifications.";
            message.error(errorMessage);
        }
    }, [saveNewNotifications, newNotifications]);

    const fetchTutorialDownloadUrl = useCallback(async () => {
        try {
            const res = await AccountAPI.downloadTutorialPdf();
            const url = await res.data.link;
            setTutorialDownloadUrl(url);
        } catch (e) {
            const errorMessage =
                e?.response?.data?.message ||
                e.message ||
                "An error occurred while fetching tutorial download link.";
            message.error(errorMessage);
        }
    }, []);

    const onMenuClick = (e) => {
        navigate("/" + e.key);
    };

    useEffect(() => {
        setHeaderCollapsed(true);
    }, [location.pathname]);

    useEffect(() => {
        if (isHidden || !payAccountId || !payAccountUserId) {
            return;
        }
        fetchPermissions();
        fetchNotifications();
        fetchTutorialDownloadUrl();
    }, [
        payAccountId,
        isHidden,
        payAccountUserId,
        url,
        fetchPermissions,
        notificationsNum,
        fetchNotifications,
        newNotifications,
        fetchTutorialDownloadUrl,
    ]);

    if (isHidden) return <>{children}</>;

    return (
        <AntdLayout className="h-screen">
            <Sider
                trigger={null}
                collapsible
                collapsed={sidebarCollpased}
                className="!bg-secondary-white p-8 hidden sm:block"
                collapsedWidth={105}
                width={342}
            >
                <div
                    className={cx("h-full flex flex-col justify-between", {
                        "animate-fade-in": !sidebarCollpased,
                    })}
                >
                    <div>
                        <a href="/">
                            {sidebarCollpased ? (
                                <SmallWealthLogo />
                            ) : (
                                <WealthLogo />
                            )}
                        </a>
                        <div className="mt-4">
                            <div className="w-full flex items-center justify-between border-b border-[#CED2DB]">
                                {!sidebarCollpased && (
                                    <span className="text-xs font-inter text-content-2">
                                        {t("general")}
                                    </span>
                                )}
                                <Button
                                    type="text"
                                    icon={
                                        sidebarCollpased ? (
                                            <MenuUnfoldOutlined className="w-5 h-5 ml-2" />
                                        ) : (
                                            <MenuFoldOutlined className="w-5 h-5" />
                                        )
                                    }
                                    onClick={() =>
                                        setSidebarCollpased(!sidebarCollpased)
                                    }
                                    style={{
                                        fontSize: "16px",
                                    }}
                                />
                            </div>

                            <div className="mt-2">
                                <Menu
                                    mode="inline"
                                    onClick={onMenuClick}
                                    className="!border-none"
                                    selectedKeys={[firstSegment]}
                                    items={[
                                        {
                                            key: "dashboard",
                                            icon: (
                                                <div className="px-4 pl-2 pr-0">
                                                    <DashboardIcon
                                                        className={cx(
                                                            "w-5 h-5",
                                                            {
                                                                "pl-1 pt-0.5":
                                                                    sidebarCollpased,
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            ),
                                            label: (
                                                <div className="text-base font-inter">
                                                    {t("dashboard")}
                                                </div>
                                            ),
                                        },
                                        {
                                            key: "notification",
                                            icon: (
                                                <div className="px-4 pl-[5px] pr-0 relative inline-block">
                                                    <NotificationIcon
                                                        className={cx(
                                                            "w-[25px] h-[25px]",
                                                            {
                                                                "pl-[3px] pt-1":
                                                                    sidebarCollpased,
                                                            }
                                                        )}
                                                    />
                                                    {sidebarCollpased &&
                                                        !!notificationsNum && (
                                                            <span className="absolute -top-[0.25px] -right-[0.25px] block h-2 w-2 bg-virgo-pay-gold rounded-full border-2 border-white" />
                                                        )}
                                                </div>
                                            ),
                                            label: (
                                                <div className="flex items-center justify-between">
                                                    <span className="text-base font-inter">
                                                        {t("notification")}
                                                    </span>
                                                    {!sidebarCollpased &&
                                                        !!notificationsNum && (
                                                            <NotificationBadge>
                                                                {
                                                                    notificationsNum
                                                                }
                                                            </NotificationBadge>
                                                        )}
                                                </div>
                                            ),
                                        },
                                        {
                                            key: "beneficiaries",
                                            icon: (
                                                <div className="relative inline-block px-4 pl-2 pr-0">
                                                    <BeneficiariesIcon
                                                        className={cx(
                                                            "w-5 h-5",
                                                            {
                                                                "pl-1 pt-0.5":
                                                                    sidebarCollpased,
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            ),
                                            label: (
                                                <div className="flex items-center justify-between">
                                                    <span className="text-base font-inter">
                                                        {t("beneficiaries")}
                                                    </span>
                                                </div>
                                            ),
                                        },
                                        {
                                            key: "send-money",
                                            icon: (
                                                <div className="px-4 pl-2 pr-0">
                                                    <SendMoneyIcon
                                                        className={cx(
                                                            "w-5 h-5",
                                                            {
                                                                "pl-1 pt-0.5":
                                                                    sidebarCollpased,
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            ),
                                            label: (
                                                <div className="text-base font-inter">
                                                    {t("sendMoney")}
                                                </div>
                                            ),
                                        },
                                        {
                                            key: "transaction",
                                            icon: (
                                                <div className="px-4 pl-1.5 pr-0">
                                                    <TransactionIcon
                                                        className={cx(
                                                            "w-6 h-6",
                                                            {
                                                                "pl-1 pt-0.5":
                                                                    sidebarCollpased,
                                                            }
                                                        )}
                                                    />
                                                </div>
                                            ),
                                            label: (
                                                <div className="text-base font-inter">
                                                    {t("transaction")}
                                                </div>
                                            ),
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col border-t border-t-[#CED2DB] border-t-1">
                        {tutorialDownloadUrl && (
                            <a
                                className={cx(
                                    "flex items-center py-2 mt-4 cursor-pointer hover:bg-hover rounded-[32px] mb-2",
                                    {
                                        "justify-center": sidebarCollpased,
                                        "px-4": !sidebarCollpased,
                                    }
                                )}
                                href={tutorialDownloadUrl}
                                download="tutorial.pdf"
                                target="_blank"
                            >
                                <TutorialIcon className="w-5 h-5" />
                                {!sidebarCollpased && (
                                    <span className="ml-2 text-base font-inter text-content-1">
                                        {t("tutorial")}
                                    </span>
                                )}
                            </a>
                        )}
                        <div
                            className={cx(
                                "flex items-center py-2 my-2 cursor-pointer hover:bg-hover rounded-[32px] hidden",
                                {
                                    "justify-center": sidebarCollpased,
                                    "px-4": !sidebarCollpased,
                                }
                            )}
                        >
                            <FaqIcon className="w-5 h-5" />
                            {!sidebarCollpased && (
                                <span className="ml-2 text-base font-inter text-content-1">
                                    {t("faq")}
                                </span>
                            )}
                        </div>
                        <Dropdown
                            overlay={langMenu} //下拉菜单配置项
                            getPopupContainer={(triggerNode) => triggerNode.parentNode} //修改挂载节点为当前父节点
                            placement="bottomCenter"
                            overlayStyle={{ width: '88px' }}
                        >
                            <div 
                                className={cx(
                                    "flex items-center py-2 cursor-pointer hover:bg-hover rounded-[32px] mb-2",
                                    {
                                        "justify-center": sidebarCollpased,
                                        "px-4": !sidebarCollpased,
                                    }
                                )}>
                                <img className="w-5 h-5" src={languageIcon} alt="LanguageIcon"></img>
                                {!sidebarCollpased && (
                                    <div className="ml-2 text-base font-inter text-content-1 flex justify-between w-full items-center">
                                        <span>{t('lng')}</span>
                                        <img src={arrowDownIcon} alt="arrowDown"></img>
                                    </div>
                                )}
                            </div>
                        </Dropdown>
                        <div
                            className={cx("flex items-center w-full", {
                                "justify-center": sidebarCollpased,
                            })}
                        >
                            <Button
                                className={cx("w-full flex items-center", {
                                    "!px-3.5 !py-5 justify-start":
                                        !sidebarCollpased,
                                    "justify-center !h-8 !w-8":
                                        sidebarCollpased,
                                })}
                                shape={sidebarCollpased ? "circle" : "round"}
                                icon={<AccountIcon className="w-5 h-5" />}
                                onClick={() => navigate("/account")}
                            >
                                {!sidebarCollpased && (
                                    <span className="text-base font-inter text-content-1">
                                        {t("account")}
                                    </span>
                                )}
                            </Button>
                        </div>
                        <div
                            className={cx(
                                "flex items-center py-2 my-2 cursor-pointer hover:bg-hover rounded-[32px]",
                                {
                                    "justify-center": sidebarCollpased,
                                    "px-4": !sidebarCollpased,
                                }
                            )}
                            onClick={() => setIsLogoutModalOpen(true)}
                        >
                            <SignoutIcon className="w-5 h-5" />
                            {!sidebarCollpased && (
                                <span className="ml-2 text-base font-inter text-content-1">
                                    {t("signout")}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </Sider>
            <AntdLayout>
                <Header
                    className={cx(
                        "block sm:hidden w-full fixed z-50 px-4 bg-secondary-white",
                        {
                            "h-full": !headerCollapsed,
                            "h-auto": headerCollapsed,
                        }
                    )}
                >
                    <div className="flex items-center justify-between h-14">
                        {headerCollapsed && headerPath ? (
                            headerPath
                        ) : (
                            <SmallWealthLogo className="w-6 h-6 mt-1.5" />
                        )}

                        <span
                            className="hamburger-box"
                            onClick={() => setHeaderCollapsed((prev) => !prev)}
                        >
                            <span
                                className={cx("white-hamburger", {
                                    "hamburger-inner-active": !headerCollapsed,
                                    "hamburger-inner": headerCollapsed,
                                })}
                            ></span>
                        </span>
                    </div>
                    <div
                        className={cx(
                            "w-full flex flex-col justify-between h-[calc(100%-56px)] overflow-auto pt-4",
                            {
                                "animate-fade-in": !headerCollapsed,
                                hidden: headerCollapsed,
                            }
                        )}
                    >
                        <div>
                            <div className="w-full leading-6 border-b border-divider">
                                <span className="text-xs font-inter text-content-2">
                                    {t("general")}
                                </span>
                            </div>

                            <div
                                className={cx(
                                    "flex items-center w-full px-4 py-2 mt-2 rounded-[32px]",
                                    {
                                        "bg-secondary text-virgo-pay-primary":
                                            url.includes("/dashboard"),
                                        "text-content-1":
                                            !url.includes("/dashboard"),
                                    }
                                )}
                                onClick={() => navigate("/dashboard")}
                            >
                                <DashboardIcon className="w-5 h-5 mx-[3px]" />
                                <span className="ml-2 text-base leading-none">
                                    {t("dashboard")}
                                </span>
                            </div>
                            <div
                                className={cx(
                                    "flex items-center w-full px-4 py-2 mt-2 rounded-[32px]",
                                    {
                                        "bg-secondary text-virgo-pay-primary":
                                            url.includes("/notification"),
                                        "text-content-1":
                                            !url.includes("/notification"),
                                    }
                                )}
                                onClick={() => navigate("/notification")}
                            >
                                <NotificationIcon className="w-[25px] h-[25px] mx-[1px]" />
                                <div className="flex items-center justify-between w-full">
                                    <span className="ml-2 text-base leading-none">
                                        {t("notification")}
                                    </span>
                                    {!!notificationsNum && (
                                        <NotificationBadge>
                                            {notificationsNum}
                                        </NotificationBadge>
                                    )}
                                </div>
                            </div>
                            <div
                                className={cx(
                                    "flex items-center w-full px-4 py-2 mt-2 rounded-[32px]",
                                    {
                                        "bg-secondary text-virgo-pay-primary":
                                            url.includes("/beneficiaries"),
                                        "text-content-1":
                                            !url.includes("/beneficiaries"),
                                    }
                                )}
                                onClick={() => navigate("/beneficiaries")}
                            >
                                <BeneficiariesIcon className="w-5 h-5 mx-[3px]" />
                                <div className="flex items-center justify-between w-full">
                                    <span className="ml-2 text-base leading-none">
                                        {t("beneficiaries")}
                                    </span>
                                </div>
                            </div>
                            <div
                                className={cx(
                                    "flex items-center w-full px-4 py-2 mt-2 rounded-[32px]",
                                    {
                                        "bg-secondary text-virgo-pay-primary":
                                            url.includes("/send-money"),
                                        "text-content-1":
                                            !url.includes("/send-money"),
                                    }
                                )}
                                onClick={() => navigate("/send-money")}
                            >
                                <SendMoneyIcon className="w-5 h-5 mx-[3px]" />
                                <span className="ml-2 text-base leading-none">
                                    {t("sendMoney")}
                                </span>
                            </div>
                            <div
                                className={cx(
                                    "flex items-center w-full px-4 py-2 mt-2 rounded-[32px]",
                                    {
                                        "bg-secondary text-virgo-pay-primary":
                                            url.includes("/transaction"),
                                        "text-content-1":
                                            !url.includes("/transaction"),
                                    }
                                )}
                                onClick={() => navigate("/transaction")}
                            >
                                <TransactionIcon className="w-6 h-6 mx-[1px]" />
                                <span className="ml-2 text-base leading-none">
                                    {t("transaction")}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col pb-8 pt-2 border-t border-b-[1px] border-t-divider">
                            {tutorialDownloadUrl && (
                                <a
                                    type="text"
                                    className="w-full flex items-center justify-start !px-[17.5px] !py-5"
                                    href={tutorialDownloadUrl}
                                    download="tutorial.pdf"
                                    target="_blank"
                                >
                                    <TutorialIcon className="w-5 h-5 mr-2" />
                                    <span className="text-base font-inter text-content-1">
                                        {t("tutorial")}
                                    </span>
                                </a>
                            )}
                            <Button
                                type="text"
                                className="w-full flex justify-start !px-[17.5px] !py-5 hidden"
                                icon={<FaqIcon className="w-5 h-5" />}
                            >
                                <span className="text-base font-inter text-content-1">
                                    {t("faq")}
                                </span>
                            </Button>
                            <Dropdown
                                overlay={langMenu} //下拉菜单配置项
                                getPopupContainer={(triggerNode) => triggerNode.parentNode} //修改挂载节点为当前父节点
                                placement="bottomCenter"
                                overlayStyle={{ width: '88px' }}
                            >
                                <div 
                                    className={"flex items-center py-2 cursor-pointer  mb-2  !px-[17.5px]"}>
                                    <img className="w-5 h-5" src={languageIcon} alt="LanguageIcon"></img>
                                    {!sidebarCollpased && (
                                        <div className="ml-2 text-base font-inter text-content-1 flex justify-between w-full items-center">
                                            <span>{t('lng')}</span>
                                            <img src={arrowDownIcon} alt="arrowDown"></img>
                                        </div>
                                    )}
                                </div>
                            </Dropdown>
                            <div className="flex items-center w-full py-2">
                                <Button
                                    className="w-full flex justify-start !px-[17.5px] !py-5"
                                    shape="round"
                                    icon={<AccountIcon className="w-5 h-5" />}
                                    onClick={() => navigate("/account")}
                                >
                                    <span className="text-base font-inter text-content-1">
                                        {t("account")}
                                    </span>
                                </Button>
                            </div>
                            <Button
                                type="text"
                                className="w-full flex justify-start !px-[17.5px] !py-5"
                                icon={<SignoutIcon className="w-5 h-5" />}
                                onClick={() => setIsLogoutModalOpen(true)}
                            >
                                <span className="text-base font-inter text-content-1">
                                    {t("signout")}
                                </span>
                            </Button>
                        </div>
                    </div>
                </Header>
                <Content
                    className={cx(
                        "pt-[88px] sm:pt-8 px-8 pb-8 overflow-y-auto flex flex-col",
                        {
                            "justify-center items-center": isLoading,
                        },
                        contentClassnames
                    )}
                >
                    {isLoading ? (
                        <Spin size="lg" />
                    ) : (
                        <div className="max-w-[1024px]">{children}</div>
                    )}
                </Content>
            </AntdLayout>
            <GeneralModal
                open={isLogoutModalOpen}
                setOpen={setIsLogoutModalOpen}
                okBtnContent={t("confirm")}
                okButtonClass="bg-virgo-pay-primary"
                title={t("signout")}
                description={t("signoutDesc")}
                customHandleOk={() => {
                    setIsLogoutModalOpen(false);
                    logout();
                }}
            />
        </AntdLayout>
    );
};

export default Layout;
